// Dependencies
import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
// Hooks & Helpers
// ...
// Wrappers
import Layout from "../wrappers/Layout"
// Components
import Link from "../components/Link"

const Stockist = ({ name, url, city, ...props }) => {

	return (
		<Link href={url} className="block link">
			<span className="upcase">{name}, </span>
			<span>{city?.name}</span>
		</Link>
	)
}

const Stockists = ({ data, pageContext }) => {

	// const stockistRegions = data.stockistRegions.group

	const { regions } = data.contentfulStockistCategory

	const matchesCategory = (categories) => categories?.find(c => c.slug === pageContext.slug) 

	return (
		<Layout>
			<section className="grid">
				<div className="stockists__grid">
					<div className="stockist__regions">
						{regions?.map((region) => {

							const hasManyCountries = region.countries?.length > 1

							return (
								<div className="stockist__regions__region" key={region.name}>
									<div className="wrapper">
										{hasManyCountries && <h3 className="btn no-hover">{region.name}</h3>}

										{region.countries?.map((country) => {

											const countryHasStockists = country.cities?.filter(city => {
												return city.stockist?.find(s => {
													return matchesCategory(s.category)
												})
											}).length > 0

											return (
												countryHasStockists && (
													<div className="stockist__regions__country" key={`${region.name}${country.name}`}>
														<div className="label">
															<h3 className={!hasManyCountries ? 'btn no-hover' : ''}>{country.name}</h3>
														</div>
														<div className="stockists">
															{country.cities?.map((city) => {
																return city.stockist?.map((stockist) => (
																	matchesCategory(stockist.category) 
																		? <Stockist {...stockist} key={`${region.name}${country.name}${stockist.name}`} /> 
																		: null
																))
															})}
														</div>
													</div>
												)
											)
										})}
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</section>
		</Layout>
	)

	// return (
	// 	<Layout>
	// 		<section className="grid innerx4 guttersx4">
	// 			<div className="span-6 start-7">
	// 				<div className="stockist__regions">
	// 					{stockistRegions.map((region, index) => {
	// 						return (
	// 							<div className="mbx4" key={region.name}>
	// 								<h3 className="btn no-hover">{region.name}</h3>
	// 								{region.stockists?.map((entry) => (
	// 									<Stockist {...entry} key={entry.id} />
	// 								))}	
	// 							</div>
	// 						)
	// 					})}
	// 				</div>
	// 			</div>
	// 		</section>
	// 	</Layout>
	// )
}

Stockists.propTypes = {
  data: PropTypes.object.isRequired
}

export default Stockists

export const StockistsPageQuery = graphql`
  query StockistsQuery($slug: String) {
		## NEW
		contentfulStockistCategory(slug: {eq: $slug}) {
			regions {
				name
				countries {
					name
					cities {
						stockist {
							name
							city {
								name
							}
							url
							category {
								slug
							}
						}
					}
				}
			}
		}

		## OLD
		stockistRegions: allContentfulStockist(
			filter: {category: {elemMatch: {slug: {eq: $slug}}}}
			sort: {fields: name}
		) {
			group(field: region___name) {
				name: fieldValue
				stockists: nodes {
					id
					name
					url
					city {
						name
						country: stockist___country {
							name
						}
					}
					region {
						name
					}
				}
			}
		}
  }
`

// export const StockistsPageQuery = graphql`
//   query StockistsQuery($continent: String, $country: String, $city: String) {
// 		allContentfulStockist(
// 			filter: {city: {slug: {eq: $city}, stockist___country: {elemMatch: {slug: {eq: $country}, stockist___continent: {elemMatch: {slug: {eq: $continent}}}}}}}
// 		) {
// 			nodes {
// 				id
// 				name
// 				url
// 				address {
// 					text: address
// 				}
// 				city {
// 					name
// 					country: stockist___country {
// 						name
// 					}
// 				}
// 			}
// 		}
//   }
// `
//($continent: String, $country: String, $city: String)
//filter: {product___department: {elemMatch: {slug: {eq: $productDept}}}, type: {elemMatch: {slug: {eq: $productType}}}}